exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-projekte-index-tsx": () => import("./../../../src/pages/projekte/index.tsx" /* webpackChunkName: "component---src-pages-projekte-index-tsx" */),
  "component---src-pages-projekte-sovd-index-tsx": () => import("./../../../src/pages/projekte/sovd/index.tsx" /* webpackChunkName: "component---src-pages-projekte-sovd-index-tsx" */),
  "component---src-pages-ueberuns-tsx": () => import("./../../../src/pages/ueberuns.tsx" /* webpackChunkName: "component---src-pages-ueberuns-tsx" */)
}

